import { useOs } from '@wpp-open/react'
import { useMemo, useState } from 'react'

import { useFetchToolbarApps } from 'api/assistant/queries/apps/useFetchToolbarApps'

export const useApps = () => {
  const { osContext } = useOs()

  const [userId] = useState(osContext.userDetails.id || '')
  const [isExternal] = useState((osContext.userDetails as any)?.isExternal)

  const { data: toolbarApps, isLoading: isLoadingToolbarApps } = useFetchToolbarApps({
    params: { userId: userId, externalUser: isExternal },
    // enabled: !!osContext.userDetails.id && 'isExternal' in osContext.userDetails,
  })

  //   const toolbarApps = { recommendedApps: [], pinnedApps: [] } as AppToolbarAPI
  //   const isLoadingToolbarApps = false

  return useMemo(() => ({ toolbarApps, isLoadingToolbarApps }), [toolbarApps, isLoadingToolbarApps])
}

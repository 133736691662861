import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { assistantApi, facadeApi } from 'api'

const apiInstances = [assistantApi, facadeApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
            // instanceConfig!.headers!.Authorization =
            //   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSWTNGSWRndVNiSDZFYzJiUS0xQ2VwY1k5RDlXT1ZiWk15Vy1VdVQwUEFnIn0.eyJleHAiOjE3MjY4NTQ4MjcsImlhdCI6MTcyNjgxODgzMywiYXV0aF90aW1lIjoxNzI2ODE4ODI3LCJqdGkiOiJiZmY4M2RlNi03MjEzLTRiZWEtYjhiMi03ZjJkYmY5OGExNWYiLCJpc3MiOiJodHRwczovL2F1dGhlbnRpY2F0ZS5vcy53cHAuY29tL2F1dGgvcmVhbG1zL2F6LWJldGEiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNGU1NzlhNDUtYTNkNy00ZjFkLTg3OWUtMjg4N2ZiOTZlNDFhIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYm94LWRldi1mZSIsInNlc3Npb25fc3RhdGUiOiI0NjFhZDZhOC1kZWQwLTRiM2ItYmI0My03OTFmNDE3MzA4ODUiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiIsImRlZmF1bHQtcm9sZXMtYXotYmV0YSJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIGVtYWlsIHByb2ZpbGUiLCJzaWQiOiI0NjFhZDZhOC1kZWQwLTRiM2ItYmI0My03OTFmNDE3MzA4ODUiLCJleHRlcm5hbFVzZXIiOmZhbHNlLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJNaWhhIEtvcm9zZWMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJtaWhhLmtvcm9zZWNAd3BwLmNvbSIsImdpdmVuX25hbWUiOiJNaWhhIiwiZmFtaWx5X25hbWUiOiJLb3Jvc2VjIiwiZW1haWwiOiJtaWhhLmtvcm9zZWNAd3BwLmNvbSJ9.D0WU9QwwyheXv37iJNiUdROOND5_zBBd6mB2rgEujmE9RYEzCez3Ql8tTX1OA28NE3Na7bDvuE4lQEGFL7sHvP6leK3SCM6LV7XJHeGypNcEAZTrywGOdaCxQWZ3CziLMiakWA5FCQKOf9RYCUgK6HdE_zK6SGTlJAb3RTLvjwtWsQalaNoR8EtN7Sez7GIyQdmPx2a_ZxWyn6URyP3c_8RRbxENHMrKPcOmOlRUhxjmw2irsXHoIW1Qx5GEWwyQSAy3Km288DSV7SP-oKjs7knTh-YOKkBCneZ2bFBIr6an-5x7xtNHSl2Rbb4agDi-HeXXauriiTQ98gLIWvtaRw'
          }

          if (tenantId) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}

import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { isAxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateConversation } from 'api/assistant/mutations/conversation/useCreateConversation'
import { useCreateQuestion } from 'api/assistant/mutations/conversation/useCreateQuestion'
import { useDeleteConversation } from 'api/assistant/mutations/conversation/useDeleteConversation'
import { usePatchConversation } from 'api/assistant/mutations/conversation/usePatchConversation'
import { useCreateFeedback } from 'api/assistant/mutations/feedback/useCreateFeedback'
import { usePatchFeedback } from 'api/assistant/mutations/feedback/usePatchFeedback'
import { useCreateTeamNotification } from 'api/assistant/mutations/notification/useCreateTeamNotification'
import { usePatchUserSettings } from 'api/assistant/mutations/userSettings/usePatchUserSettings'
import { AssistantSizes } from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings'
import { FeedbackReaction, FeedbackSelectOptions } from 'components/assistant/chat/chatBubble/feedbackForm/FeedbackForm'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TaskDto, TaskDtoCreate } from 'types/dto/TaskDto'

import { useToast } from './useToast'

interface CreateEmptyConversationArgs {
  question: string
}

interface AskQuestionArgs {
  conversationId: string
  question: string
}

interface PatchConversationArgs {
  conversationId: string
  name?: string
  pinned?: boolean
}

interface DeleteConversationArgs {
  conversationId: string
}

interface PatchUserSettingsDto {
  settingsId: string
  windowSize?: AssistantSizes
  mentionsEnabled?: boolean
}

interface AddFeedbackArgs {
  messageId: string
  reaction: FeedbackReaction
  subTypes?: FeedbackSelectOptions[] | null
  feedback?: string | null
}

interface PatchFeedbackArg {
  feedback_message_id: string
  reaction?: FeedbackReaction
  subTypes?: FeedbackSelectOptions[] | null
  feedback?: string | null
}

export const useAssistant = () => {
  const { osContext } = useOs()
  const { t } = useTranslation()
  const { id: userId } = osContext.userDetails
  const queryClient = useQueryClient()

  const { mutateAsync: createConversation } = useCreateConversation()
  const { mutateAsync: createQuestion } = useCreateQuestion()
  const { mutateAsync: patchConversationMutation } = usePatchConversation()
  const { mutateAsync: deleteConversationMutation } = useDeleteConversation()
  const { mutateAsync: patchUserSettingsMutation } = usePatchUserSettings()
  const { mutateAsync: createFeedback } = useCreateFeedback()
  const { mutateAsync: patchFeedbackMutation } = usePatchFeedback()
  const { mutateAsync: createTeamNotificationMutation } = useCreateTeamNotification()

  const { showToast } = useToast()

  const getClientMdId = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'CLIENT') {
          // TODO Remove as any when types for osContext are updated
          if ((osContext?.workspace.mapping[key] as any).mdId) {
            return (osContext?.workspace.mapping[key] as any).mdId
          }
          return null
        }
      }
    }

    return null // Return null if no CLIENT type found
  }, [osContext?.workspace])

  const startConversation = useCallback(
    async ({ question }: CreateEmptyConversationArgs): Promise<TaskDtoCreate | number | undefined> => {
      try {
        const response = await createConversation({
          userId,
          messages: ['', question],
          mdId: getClientMdId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        // console.error(err)
      }
    },
    [createConversation, getClientMdId, userId],
  )

  const askQuestion = useCallback(
    async ({ conversationId, question }: AskQuestionArgs): Promise<TaskDto | number | undefined> => {
      try {
        const response = await createQuestion({
          conversationId,
          question,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        // console.error(err)
      }
    },
    [createQuestion],
  )

  const patchConversation = useCallback(
    async ({ conversationId, name, pinned }: PatchConversationArgs) => {
      try {
        await patchConversationMutation({ conversationId, name, pinned })
        queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
      } catch (err: any) {
        console.error(err)
        if (err.response.data.detail) {
          showToast({
            message: err.response.data.detail,
            type: 'error',
            duration: 4000,
          })
        }
      }
    },
    [patchConversationMutation, queryClient, showToast],
  )

  const deleteConversation = useCallback(
    async ({ conversationId }: DeleteConversationArgs) => {
      try {
        await deleteConversationMutation({ chatId: conversationId })
        queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
      } catch (err) {
        console.error(err)
      }
    },
    [deleteConversationMutation, queryClient],
  )

  const patchUserSettings = useCallback(
    async ({ settingsId, windowSize, mentionsEnabled }: PatchUserSettingsDto) => {
      try {
        await patchUserSettingsMutation({ settingsId, windowSize, mentionsEnabled })
        queryClient.invalidateQueries([ApiQueryKeys.USER_SETTINGS])
        showToast({
          message: t('settings.message_success'),
          type: 'success',
          duration: 4000,
        })
      } catch (err) {
        console.error(err)
        showToast({
          message: t('settings.message_failure'),
          type: 'error',
          duration: 4000,
        })
      }
    },
    [patchUserSettingsMutation, queryClient, showToast, t],
  )

  const addFeedback = useCallback(
    async ({ messageId, reaction, subTypes = null, feedback = null }: AddFeedbackArgs) => {
      const res = await createFeedback({ userId, messageId, reaction, subTypes, feedback })
      return res.data
    },
    [createFeedback, userId],
  )

  const patchFeedback = useCallback(
    async ({ feedback_message_id, reaction, subTypes = null, feedback = null }: PatchFeedbackArg) => {
      const res = await patchFeedbackMutation({ feedback_message_id, reaction, subTypes, feedback })
      return res.data
    },
    [patchFeedbackMutation],
  )

  const notifyTeam = useCallback(async () => {
    try {
      await createTeamNotificationMutation(undefined)

      showToast({
        message: t('toasts.notification_success'),
        type: 'success',
        duration: 4000,
      })
    } catch (err) {
      console.error(err)

      showToast({
        message: t('toasts.notification_failure'),
        type: 'error',
        duration: 4000,
      })
    }
  }, [createTeamNotificationMutation, showToast, t])

  return useMemo(
    () => ({
      startConversation,
      askQuestion,
      patchConversation,
      deleteConversation,
      patchUserSettings,
      addFeedback,
      patchFeedback,
      notifyTeam,
    }),
    [
      startConversation,
      askQuestion,
      patchConversation,
      deleteConversation,
      patchUserSettings,
      addFeedback,
      patchFeedback,
      notifyTeam,
    ],
  )
}

import { fetchToolbarApps } from 'api/assistant/fetchers/apps/fetchToolbar'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AppToolbarAPI } from 'types/app/app'

export const useFetchToolbarApps = createUseQuery({
  queryKey: ApiQueryKeys.TOOLBAR_APPS,
  fetcher: fetchToolbarApps,
  selector: res => res?.data ?? ({ pinnedApps: [], recommendedApps: [] } as AppToolbarAPI),
})

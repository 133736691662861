import { useContext } from 'react'

import { HubsContext } from 'contexts/HubsContext'

export const useHubsContext = () => {
  const context = useContext(HubsContext)
  if (!context) {
    throw new Error('useHubsContext must be used within a AppsProvider')
  }

  return {
    ...context,
  }
}

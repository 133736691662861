import { assistantApi } from 'api'
import { AppAPI } from 'types/app/app'

export const updateApp = ({
  pin,
  tenantId,
  appId,
  userId,
}: {
  pin: boolean
  tenantId: string
  appId: string
  userId: string
}) => {
  return pin
    ? assistantApi.post<{ data: AppAPI[] }>(`/apps/${appId}/pin`, {
        tenantId,
        appId,
        userId,
      })
    : assistantApi.delete<{ data: AppAPI[] }>(`/apps/${appId}/pin`, {
        tenantId,
        appId,
        userId,
      } as any)
}

import { assistantApi } from 'api'
import { ConversationDto } from 'types/dto/ConversationDto'

export const searchSavedConversations = ({
  userId,
  mdId,
  searchQuery,
}: {
  userId: string
  mdId: string | null
  searchQuery: string
}) =>
  assistantApi.get<ConversationDto[]>('/chats/search', {
    params: { userId, searchQuery, mdId },
  })

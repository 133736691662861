import { AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'

export enum HubType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export interface Hub {
  id: string
  name: string
  tenantId: string
  azId: string
  organizationUnitId: string
  isActive: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  type: HubType
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  azMeta: {
    groups: {
      viewerUsersId: string
      adminUsersId: string
    }
  }
  accessRequestable: boolean
}

export interface HubsFilters {
  isActive?: boolean
  type?: HubType
  canManage?: boolean
  accessRequestable?: boolean
  isGranted?: boolean
  organizationUnitIds?: string[]
}

export type Params = PaginationParams<{
  searchName?: string
  sort?: string
  filters?: HubsFilters
}>

export type HubsResponse = PaginatedResponse<Hub>

export const fetchHubsApi = ({ page, itemsPerPage, searchName, sort, filters }: Params) =>
  facadeApi.get<HubsResponse>('/hubs', {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      sort,
      filter: { searchName, ...filters },
    }),
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })

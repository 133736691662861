import { useContext } from 'react'

import { AppsContext } from 'contexts/AppsContext'

export const useAppsContext = () => {
  const context = useContext(AppsContext)
  if (!context) {
    throw new Error('useAppsContext must be used within a AppsProvider')
  }

  return {
    ...context,
  }
}

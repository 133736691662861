import { assistantApi } from 'api'
import { PaginationParams } from 'api/common/types'
import { HistoryConversationsDto } from 'types/dto/ConversationDto'

export const fetchSavedConversations = ({
  page,
  itemsPerPage,
  userId,
  mdId,
  chats_num,
}: PaginationParams<{ userId: string; chats_num: number; mdId: string | null }>) =>
  assistantApi.get<HistoryConversationsDto>('/chats/history', {
    params: { page, itemsPerPage, userId, chats_num, mdId },
  })
// assistantApi.get<PaginatedResponse<ConversationDto>>('/chats/history', {
//   params: { page, itemsPerPage, userId, tenantId },
// })
